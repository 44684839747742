import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';
import PageScrollTop from './components/pageToTop/PageScrollTop';
import CookieConsent from "react-cookie-consent";
import './assets/scss/style.scss';

// Lazy loading pages
const Splash = lazy(() => import("./pages/BusinessConsulting2"));
const Mobile = lazy(() => import("./pages/Mobile"));
const Webstore = lazy(() => import("./pages/Rental-Web-Platform"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Contact = lazy(() => import("./pages/Contact"));
const LiftsRental = lazy(() => import("./pages/Lifts-Rental"));
const HeavyEquipment = lazy(() => import("./pages/Heavy-Equipment-Rental"));
const ToolsRental = lazy(() => import("./pages/Tools-Rental"));
const Demo = lazy(() => import("./pages/Demo"));
const Error = lazy(() => import("./pages/Error"));
const ArticleCard = lazy(() => import('./elements/blog/ArticleCard'));

const App = () => {
    const [seoLoaded, setSeoLoaded] = React.useState(false);
    const supportedLanguages = ['us', 'pl', 'de', 'it', 'nld']; // Obsługiwane języki
    const defaultLanguage = 'us'; // Język domyślny
    const supportedPaths = [
        "contact",
        "demo",
        "lifts-rental",
        "mobile-application-rental-solutions",
        "rental-web-platform",
        "dashboard-management-rental-platform",
        "heavy-equipment-rental",
        "tools-rental",
        "error",
    ];

    useEffect(() => {
        i18next.loadNamespaces('seo').then(() => {
            console.log('SEO translations loaded');
            setSeoLoaded(true);
        });
    }, []);

    const getPreferredLanguage = () => {
        const browserLanguage = navigator.language.slice(0, 2);
        return supportedLanguages.includes(browserLanguage) ? browserLanguage : defaultLanguage;
    };

    if (!seoLoaded) return null;

    return (
        <>
            <Helmet>
                {/* Google Analytics */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-19ST0DKKBH"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-19ST0DKKBH');
                    `}
                </script>
                {/* Simple Analytics */}
                <script async src="https://scripts.simpleanalyticscdn.com/latest.js"></script>
                {/* Ahrefs */}
                <script src="https://analytics.ahrefs.com/analytics.js" data-key="L8Iwb7OMk1lNysX698GP/w" async></script>
            </Helmet>

            <Router>
                <PageScrollTop>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            {/* Obsługa strony głównej */}
<Route
    path={`${process.env.PUBLIC_URL}/`}
    element={
        window.location.pathname.includes(`/${i18next.language}`) ?
            null :
            <Navigate
                to={`${process.env.PUBLIC_URL}/${i18next.language || getPreferredLanguage()}/`}
                replace
            />
    }
/>

                            {/* Obsługa tras bez języka */}
                            {supportedPaths.map((path) => (
                                <Route
                                    key={path}
                                    path={`${process.env.PUBLIC_URL}/${path}`}
                                    element={
                                        <Navigate
                                            to={`${process.env.PUBLIC_URL}/${getPreferredLanguage()}/${path}`}
                                            replace
                                        />
                                    }
                                />
                            ))}

                            {/* Obsługa tras z dynamicznym językiem */}
                            <Route path={`${process.env.PUBLIC_URL}/:language`} element={<LanguageRoute component={Splash} supportedLanguages={supportedLanguages} />} />
                            <Route path={`${process.env.PUBLIC_URL}/:language/mobile-application-rental-solutions`} element={<LanguageRoute component={Mobile} supportedLanguages={supportedLanguages} />} />
                            <Route path={`${process.env.PUBLIC_URL}/:language/rental-web-platform`} element={<LanguageRoute component={Webstore} supportedLanguages={supportedLanguages} />} />
                            <Route path={`${process.env.PUBLIC_URL}/:language/dashboard-management-rental-platform`} element={<LanguageRoute component={Dashboard} supportedLanguages={supportedLanguages} />} />
                            <Route path={`${process.env.PUBLIC_URL}/:language/contact`} element={<LanguageRoute component={Contact} supportedLanguages={supportedLanguages} />} />
                            <Route path={`${process.env.PUBLIC_URL}/:language/lifts-rental`} element={<LanguageRoute component={LiftsRental} supportedLanguages={supportedLanguages} />} />
                            <Route path={`${process.env.PUBLIC_URL}/:language/heavy-equipment-rental`} element={<LanguageRoute component={HeavyEquipment} supportedLanguages={supportedLanguages} />} />
                            <Route path={`${process.env.PUBLIC_URL}/:language/tools-rental`} element={<LanguageRoute component={ToolsRental} supportedLanguages={supportedLanguages} />} />
                            <Route path={`${process.env.PUBLIC_URL}/:language/demo`} element={<LanguageRoute component={Demo} supportedLanguages={supportedLanguages} />} />
                            <Route path={`${process.env.PUBLIC_URL}/:language/error`} element={<Error />} />

                            {/* Trasa artykułu */}
                            <Route path={`${process.env.PUBLIC_URL}/:language/blog/:slug`} element={<LanguageRoute component={ArticleCard} supportedLanguages={supportedLanguages} />} />

                            {/* Obsługa błędów dla nieznanych tras */}
                            <Route
                                path="*"
                                element={
                                    <Navigate
                                        to={`${process.env.PUBLIC_URL}/${i18next.resolvedLanguage || defaultLanguage}/error`}
                                        replace
                                    />
                                }
                            />
                        </Routes>
                    </Suspense>
                </PageScrollTop>

                <CookieConsent
                    location="bottom"
                    declineButtonText="Decline"
                    cookieName="myAppCookieConsent"
                    style={{ background: "#242424", color: "#FFF" }}
                    buttonStyle={{ background: "#3399FF", color: "#FFF", fontSize: "15px" }}
                    expires={150}
                >
                    We use cookies to improve your experience. By using our website, you agree to the use of cookies as described in our Privacy Policy.
                </CookieConsent>
            </Router>
        </>
    );
};

const LanguageRoute = ({ component: Component, supportedLanguages }) => {
    const { language } = useParams();
    const navigate = useNavigate(); // używamy navigate do zmiany URL

    useEffect(() => {
        const loadI18n = async () => {
            if (!supportedLanguages.includes(language)) {
                navigate(`/pl${window.location.pathname}`); // Jeśli język nie jest wspierany, ustaw na domyślny
                await i18next.changeLanguage('pl');
            } else if (language !== i18next.language) {
                await i18next.changeLanguage(language);
            }
        };

        loadI18n();
    }, [language, navigate, supportedLanguages]);

    if (!supportedLanguages.includes(language)) {
        return <Navigate to={`${process.env.PUBLIC_URL}/pl/error`} replace />;
    }

    return <Component />;
};

export default App;
